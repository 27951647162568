<template>
  <div>
    <AppHeader
      :class="[readyToPick ? 'bg-green' : 'bg-orange']"
      v-if="!isMobile"
    >
      <template v-slot:left-action>
        <close class="close" @click.prevent="closePacketDetail" />
      </template>

      <template v-slot:title>
        <h2>{{ $t("packetDetail.release-packet") }}</h2>
        <h1 v-if="packet.barcode">
          {{ packet.barcode.substring(0, packet.barcode.length - 3)
          }}<span :class="[readyToPick ? 'jungle-green' : 'dark-red']">{{
            packet.barcode.substring(packet.barcode.length - 3)
          }}</span>
        </h1>
      </template>
    </AppHeader>

    <main :style="{ paddingBottom: footerHeight + 'px' }">
      <section
        :class="[
          'state',
          readyToPick ? 'bg-light-green green' : 'bg-light-pink red'
        ]"
      >
        <h2>{{ $t("packetDetail.state") }}</h2>
        <h3>
          <span v-if="readyToPick">{{
            $t("packetDetail.state-text-paid")
          }}</span>
          <span v-else>{{ $t("packetDetail.state-text-waiting") }}</span>
          <template v-if="packet.cod.rounded_amount_cents > 0 && paidInCash">
            {{ formatRoundedCod(packet) }}
          </template>
          <template v-else-if="packet.cod.amount_cents > 0">
            {{ formatCod(packet) }}
          </template>
        </h3>
      </section>
      <section class="address container">
        <div class="flex-group">
          <div>
            <h2>{{ $t("packetDetail.addressee") }}</h2>
            <h3>
              {{ packet.recipient.first_name }} {{ packet.recipient.last_name }}
            </h3>
          </div>
          <div>
            <h2>{{ $t("packetDetail.sender") }}</h2>
            <strong>{{ sender }}</strong>
          </div>
        </div>
        <h2>{{ $t("packetDetail.point") }}</h2>
        <strong>{{ packet.shelf }}</strong>
        <div v-if="packet.return_acceptance == 'unpacked'">
          <h2>{{ $t("packetDetail.more-information") }}</h2>
          <div class="flex-align-group">
            <alert-circle-icon :size="20" class="alert-circle-icon" />
            <strong
              ><p>{{ $t("packetDetail.packet-unpacking") }}</p></strong
            >
          </div>
        </div>
      </section>
      <PacketPayBox
        v-if="packet.cod.rounded_amount_cents > 0 && !readyToPick"
        :title="$t('packetDetail.pay')"
        :ready-to-pick="readyToPick"
        :amount="packet.cod.amount_cents"
        :currency="packet.cod.currency"
        secondary
      />
      <PacketPayBox
        :title="packetPayBoxTitle"
        :ready-to-pick="readyToPick"
        :amount="
          packet.cod.rounded_amount_cents > 0 && !packet.cod.paid
            ? packet.cod.rounded_amount_cents
            : packet.cod.amount_cents
        "
        :currency="packet.cod.currency"
      />
      <section class="error">
        <Errors :errors="errors" />
      </section>
      <section ref="count" v-if="!readyToPick" class="count container">
        <label>
          {{ $t("packetDetail.cash") }}
          <div class="flex-group">
            <input
              @touchend="scrollUp"
              @focus="scrollUp"
              @input="validateAmountCash"
              type="number"
              min="0"
              :value="amountCash"
            />
            <p>{{ packet.cod.currency }}</p>
          </div>
        </label>
        <p v-if="cashBack > 0">
          {{ $t("packetDetail.cash-return") }}
          <span class="red">
            <strong> {{ formatPrice(cashBack, packet.cod.currency) }}</strong>
          </span>
        </p>
      </section>
    </main>
    <footer ref="footer" v-if="packet.barcode">
      <div class="container" v-if="readyToPick">
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="printReceipt"
          >{{ $t("packetDetail.print-confirmation") }}</a
        >

        <a href="#" class="btn btn-primary btn-green" @click.prevent="finish">{{
          $t("packetC2Creceive.finish")
        }}</a>

        <a href="#" class="a-black" @click.prevent="cancelPacketDetail">{{
          $t("packetDetail.cancel-release")
        }}</a>
      </div>
      <div class="container" v-else>
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="payWithCard"
          v-if="isTransactionAllowed && packet.card_payment_allowed"
          >{{ $t("packetDetail.card-payment") }}</a
        >

        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="payWithSms"
          v-if="packet.card_payment_allowed"
        >
          {{ $t("packetDetail.sms-payment") }}
        </a>

        <a
          href="#"
          class="btn btn-primary btn-green"
          @click.prevent="releasePacket"
          >{{ $t("packetDetail.packet-release") }}</a
        >

        <a href="#" class="a-black" @click.prevent="cancelPacketDetail">{{
          $t("packetDetail.cancel-release")
        }}</a>
      </div>
    </footer>
    <OverlayPayment
      v-if="showPaymentOverlay"
      :type="type"
      @hideOverlay="hidePaymentOverlayScreen"
    />
    <OverlayCheckTerminal
      v-if="showCheckTerminalOverlay"
      @hideOverlay="hideCheckTerminalOverlayScreen"
    />
    <OverlayCancelDelivery
      v-if="showCancelDeliveryOverlay"
      :showBack="showCancelDeliveryBack"
      @hideOverlay="hideCancelDeliveryOverlay"
    />
    <OverlayAccept
      v-if="showAcceptOverlay"
      :heading="$t('packetDetail.packet-released')"
    />
    <Spinner v-if="loading" />
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations, mapGetters } from "vuex";
import { isEmpty, delay } from "lodash";
import { PAYMENT_TYPES } from "@/store/payment.type";
import { MOBILE_TYPES } from "@/util/mobile.type";
import Errors from "@/components/Errors";
import AppHeader from "@/components/AppHeader";
import Close from "vue-material-design-icons/Close.vue";
import AlertCircleIcon from "vue-material-design-icons/AlertCircle";
import OverlayPayment from "@/components/OverlayPayment";
import OverlayCheckTerminal from "@/components/OverlayCheckTerminal";
import OverlayAccept from "@/components/OverlayAccept";
import OverlayCancelDelivery from "@/components/OverlayCancelDelivery";
import PacketPayBox from "@/components/PacketPayBox";
import { setActualFooterHeight } from "@/mixins/footer";
import { getParams } from "@/mixins/platform-params.js";
import { sendHeadersToMobile } from "@/mixins/send-headers.js";
import { formatCod } from "@/mixins/format-cod";
import Spinner from "@/components/Spinner";
import { ERRORS } from "@/store/packet.module";

const AMOUNT_CASH_MAX_LENGTH = 10;

export default {
  name: "PacketDetail",
  mixins: [setActualFooterHeight, getParams, sendHeadersToMobile, formatCod],
  components: {
    Errors,
    AppHeader,
    Close,
    AlertCircleIcon,
    OverlayPayment,
    OverlayAccept,
    OverlayCheckTerminal,
    OverlayCancelDelivery,
    PacketPayBox,
    Spinner
  },
  data() {
    return {
      code: this.$route.params.code,
      type: PAYMENT_TYPES.CARD,
      showPaymentOverlay: false,
      showCheckTerminalOverlay: false,
      showAcceptOverlay: false,
      showCancelDeliveryOverlay: false,
      showCancelDeliveryBack: true,
      amountCash: null
    };
  },

  beforeRouteLeave(to, from, next) {
    // We want to show the error on next if cancel delivery fails.
    if (!this.errors.some(e => e.reason === ERRORS.CANNOT_BE_CANCELLED)) {
      this.clearErrors();
    }
    this.resetPacketAndPaymentState();
    next();
  },

  async mounted() {
    if (isEmpty(this.packet)) {
      await this.searchPassword(this.code);
    }
    this.setActualFooterHeight(); // Set footer height after data fetch
  },

  computed: {
    ...mapState(["errors", "loading"]),
    ...mapState("packet", [
      "packet",
      "sender",
      "readyToPick",
      "readyForUndelivery",
      "paidByCard",
      "paidInCash",
      "paidWithDevice"
    ]),
    ...mapGetters("auth", ["isTransactionAllowed"]),

    // Calculate the amount of cash that should be returned to the customer
    cashBack() {
      const cashAmount = this.amountCash * 100;
      const codAmount =
        this.packet.cod.rounded_amount_cents > 0
          ? this.packet.cod.rounded_amount_cents
          : this.packet.cod.amount_cents;

      return cashAmount - codAmount;
    },

    // Choose the correct title for the pay box
    packetPayBoxTitle() {
      let title;
      const isCodCashAmount = this.packet.cod.rounded_amount_cents > 0;

      if (isCodCashAmount && !this.readyToPick) {
        title = this.$t("packetDetail.pay_in_cash");
      } else if (isCodCashAmount && this.paidInCash) {
        title = this.$t("packetDetail.cod_paid_in_cash");
      } else if (this.packet.cod.amount_cents > 0 && this.paidInCash) {
        title = this.$t("packetDetail.paid_in_cash");
      } else if (this.packet.cod.amount_cents > 0 && !this.packet.cod.paid) {
        title = this.$t("packetDetail.pay");
      } else if (this.packet.cod.amount_cents > 0) {
        title = this.$t("packetDetail.paid");
      } else {
        title = this.$t("packetDetail.cod");
      }

      return title;
    }
  },

  watch: {
    readyToPick(value) {
      if (value) {
        this.sendHeadersToMobile(
          this.packet.barcode,
          this.code,
          this.readyToPick
        );

        this.showAcceptOverlayScreen();
      }
    },

    readyForUndelivery(value) {
      if (value) {
        this.disableCancelUndeliver();
        this.openCancelDeliveryOverlay();
      }
    }
  },

  methods: {
    ...mapActions(["resetPacketAndPaymentState"]),
    ...mapActions("packet", [
      "searchPassword",
      "deliverPacket",
      "undeliverPacket",
      "printDeliveryReceipt",
      "printCodReceipt"
    ]),

    ...mapActions("payment", [
      "returnPayment",
      "payInCash",
      "createCardPayment",
      "createSMSPayment"
    ]),
    ...mapMutations(["clearErrors"]),

    finish() {
      this.$router.replace({
        name: "packet-search",
        query: {
          platform: this.platform,
          device: this.device
        }
      });
    },

    scrollUp() {
      delay(() => {
        this.$refs.count.scrollIntoView({ behavior: "smooth" });
      }, 500);
    },

    validateAmountCash({ target }) {
      const currentLength = target.value.length;

      if (currentLength > AMOUNT_CASH_MAX_LENGTH) {
        // Force the input to re-render,
        // even if the state value doesn't change
        this.$forceUpdate();

        return;
      }

      this.amountCash = target.value;
    },

    // DETAIL CLOSING
    closePacketDetail() {
      if (this.readyToPick) {
        if (window.confirm(this.$t("packetDetail.window-close"))) {
          this.finish();
        }
      } else {
        this.openCancelDeliveryOverlay();
      }
    },

    disableCloseOnMobile(value) {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.disableClose(value);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.disableClose.postMessage(value);
        }
      }
    },

    // DELIVERY CANCELLATION
    async cancelPacketDetail() {
      if (window.confirm(this.$t("packetDetail.cancel-packet-confirm"))) {
        if (this.isPaymentDevice && this.paidWithDevice) {
          window.PacketaPPA.returnPacketDelivery(
            this.packet.barcode,
            this.packet.cod.amount_cents,
            this.packet.cod.currency
          );

          return;
        }

        if (!this.readyToPick || !this.packet.cod.paid) {
          this.disableCancelUndeliver();
          this.openCancelDeliveryOverlay();

          return;
        }

        this.disableCancelUndeliver();
        this.cancelDetailWithTransaction(this.paidByCard);
      }
    },

    disableCancelUndeliver() {
      this.showCancelDeliveryBack = false;

      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.disableCancelForUndeliver(true);
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.disableCancelForUndeliver.postMessage(
            true
          );
        }
      }
    },

    async cancelDetailWithTransaction(checkStatus) {
      const response = await this.returnPayment(this.packet.barcode);
      if (!response) return;

      checkStatus
        ? this.openCheckTerminalOverlay()
        : this.openCancelDeliveryOverlay();
    },

    // PAYMENTS
    async payWithCard() {
      if (this.isPaymentDevice) {
        window.PacketaPPA.payByCard(
          this.packet.barcode,
          this.packet.cod,
          this.packet.currency
        );

        return;
      }

      const response = await this.createCardPayment(this.packet.barcode);
      if (!response) return;

      this.disableCloseOnMobile(true);
      this.type = PAYMENT_TYPES.CARD;
      this.showPaymentOverlay = true;
    },

    async payWithSms() {
      const response = await this.createSMSPayment(this.packet.barcode);
      if (!response) return;

      this.disableCloseOnMobile(true);
      this.type = PAYMENT_TYPES.SMS;
      this.showPaymentOverlay = true;
    },

    releasePacket() {
      // We need to send collected cod information back to backend,
      // in case the amounts change during the process.
      const collectedAmountCents =
        this.packet.cod.rounded_amount_cents > 0
          ? this.packet.cod.rounded_amount_cents
          : this.packet.cod.amount_cents;

      this.payInCash({
        barcode: this.packet.barcode,
        cod: {
          ...this.packet.cod,
          rounded_amount_cents: collectedAmountCents
        }
      });

      this.sendHeadersToMobile(
        this.packet.barcode,
        this.code,
        this.readyToPick
      );

      this.showAcceptOverlayScreen();
    },

    // PRINTS
    async printReceipt() {
      if (this.packet.cod.amount_cents > 0) {
        await this.printCodReceipt(this.packet.barcode);
      } else {
        await this.printDeliveryReceipt(this.packet.barcode);
      }
    },

    // OVERLAYS
    openCancelDeliveryOverlay() {
      if (this.isMobile) {
        if (this.platform == MOBILE_TYPES.ANDROID) {
          window.PacketaPPA.closeWithReason();
        } else if (this.platform == MOBILE_TYPES.IOS) {
          window.webkit.messageHandlers.closeWithReason.postMessage({});
        }
      } else {
        this.showCancelDeliveryOverlay = true;
      }
    },

    openCheckTerminalOverlay() {
      this.disableCloseOnMobile(true);
      this.showCheckTerminalOverlay = true;
    },

    showAcceptOverlayScreen() {
      this.showAcceptOverlay = true;
      delay(() => {
        this.showAcceptOverlay = false;
      }, 4000);
    },

    hidePaymentOverlayScreen() {
      this.showPaymentOverlay = false;
      this.disableCloseOnMobile(false);
    },

    hideCheckTerminalOverlayScreen(successAndShowAlert) {
      this.showCheckTerminalOverlay = false;
      this.disableCloseOnMobile(false);

      if (successAndShowAlert.success) {
        this.disableCancelUndeliver();
        this.openCancelDeliveryOverlay();
      } else {
        if (successAndShowAlert.showAlert) {
          window.alert(this.$t("packetDetail.window-close2"));
        }
        this.finish();
      }
    },

    hideCancelDeliveryOverlay() {
      this.showCancelDeliveryOverlay = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.state {
  width: 100%;
  height: 36px;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  padding: 0 17px;
  box-shadow: 0 2px 6px -1px rgba(0, 0, 0, 0.1);

  h3 {
    margin-left: 3px;
  }
}

.address {
  box-sizing: border-box;
  padding: 9px 16px 12px;

  h2 {
    color: $light-grey;
    font-weight: 400;
  }

  h3 {
    font-size: 1rem;
  }

  strong {
    font-size: 0.813rem;
  }

  .flex-group {
    display: flex;
    margin: 8px 0 4px;

    div {
      flex: 1;
    }
  }

  .flex-align-group {
    display: flex;
    align-items: center;

    span {
      height: 20px;
      margin-right: 3px;
      color: $orange-red;
    }
  }
}

.count {
  padding: 10px 40px 0;

  .flex-group {
    display: flex;
    align-items: center;
  }

  input {
    margin: 3px 0;
  }

  label,
  p {
    margin-left: 5px;

    p {
      font-size: 1.25rem;
    }
  }
}

footer {
  a {
    margin-bottom: 10px;

    &.a-black:last-child {
      display: inline-block;
      margin: 16px 0 26px;
    }
  }
}
</style>
