// TODO use getActualLanguage if needed
const FORMATTER_LOCALE = "cs-CZ";

const FORMATTER_OPTIONS = {
  minimumFractionDigits: 0,
  maximumFractionDigits: 2
};

const FORMATTER = new Intl.NumberFormat(FORMATTER_LOCALE, FORMATTER_OPTIONS);

/**
 * Format amount_cents and currency symbol into a price string.
 * @param {number} amount - amount in cents
 * @param {string} currency - currency symbol/identifier
 *
 * @example
 *  formatPrice(1, "$");        // => "0,01 $"
 *  formatPrice(1050, "EUR");   // => "10,5 EUR"
 *  formatPrice(100000, "Kč"); // => "1 000 Kč"
 */
export const formatPrice = (amount, currency) => {
  const formattedAmount = formatAmount(amount);

  return `${formattedAmount} ${currency}`;
};

export const formatAmount = amountCents => {
  const amount = amountCents / 100;

  return FORMATTER.format(amount);
};

export default formatPrice;
