<template>
  <section :class="sectionClass">
    <h2>{{ title }}</h2>
    <h3 :class="[readyToPick ? 'green' : 'red']">
      {{ codValue }}
    </h3>
  </section>
</template>

<script>
import { formatCod } from "@/mixins/format-cod";

export default {
  name: "PacketPayBox",
  mixins: [formatCod],
  props: {
    title: String,
    readyToPick: Boolean,
    secondary: {
      type: Boolean,
      default: false
    },
    amount: Number,
    currency: String
  },
  computed: {
    sectionClass() {
      const classArray = [];

      if (!this.secondary) {
        classArray.push("pay");
        classArray.push(
          this.readyToPick
            ? "border-bottom-green bg-light-green"
            : "border-bottom-red bg-light-pink"
        );
      } else {
        classArray.push("pay-secondary");
      }

      return classArray.join(" ");
    },

    codValue() {
      const withoutCod = this.amount === 0 && this.readyToPick;

      return withoutCod
        ? this.$t("packetDetail.without-cod")
        : this.formatPrice(this.amount, this.currency);
    }
  }
};
</script>

<style lang="scss" scoped>
.pay {
  width: 100%;
  text-align: center;
  padding: 16px 0 11px;
  border-top: 1px solid $grey;

  h2 {
    font-size: 0.813rem;
    text-transform: uppercase;
  }

  h3 {
    font-size: 1.875rem;
  }

  &-secondary {
    @extend .pay;
    padding: 8px 0;

    h2 {
      font-size: 0.7rem;
      font-weight: 400;
      color: $light-grey;
    }

    h3 {
      font-size: 1rem;
      color: $black;
    }
  }
}
</style>
