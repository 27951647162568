<template>
  <div>
    <section class="overlay-payment text-center">
      <div class="content white">
        <Errors :errors="errors" />
        <p>
          {{ $t("packetDetail.terminal-instructions") }}
        </p>
        <a
          href="#"
          class="btn btn-primary btn-white"
          @click.prevent="checkPaymentStatus"
          ><cached-icon :size="24" class="icon" />{{
            $t("packetDetail.check-state")
          }}</a
        >
        <a href="#" class="white" @click.prevent="abortPaymentRefund"
          ><close class="close icon" :size="20" />{{
            $t("packetDetail.cancel")
          }}</a
        >
      </div>
    </section>
  </div>
</template>

<script>
import { mapState, mapActions, mapMutations } from "vuex";
import { delay } from "lodash";
import Errors from "@/components/Errors";
import CachedIcon from "vue-material-design-icons/Cached.vue";
import Close from "vue-material-design-icons/Close.vue";

const REPEAT_CHECK_STATUS = 3 * 1000;
const CANCEL_CHECK_STATUS = 3 * 60 * 1000;

export default {
  name: "OverlayCheckTerminal",
  components: { Errors, CachedIcon, Close },

  computed: {
    ...mapState(["errors"]),
    ...mapState("payment", ["paymentReturnId", "transactionFailed"])
  },

  mounted() {
    this.delayCheckRegularlyPaymentStatus();
  },

  beforeDestroy() {
    this.clearAllTimeouts();
  },

  methods: {
    ...mapActions("payment", ["getPaymentReturnDetail"]),
    ...mapMutations(["clearErrors"]),

    async checkPaymentStatus() {
      const isCompleted = await this.getPaymentReturnDetail(
        this.paymentReturnId
      );

      if (this.transactionFailed) {
        this.cancelRefundProcess(true);
      }

      if (!isCompleted) return;

      this.hideOverlayWithSuccess(true);
    },

    delayCheckRegularlyPaymentStatus() {
      this.intervalId = setInterval(() => {
        this.checkPaymentStatus();
      }, REPEAT_CHECK_STATUS);

      // Cancel process after some period of time
      this.cancelId = delay(() => {
        this.cancelRefundProcess(true);
      }, CANCEL_CHECK_STATUS);
    },

    abortPaymentRefund() {
      if (window.confirm(this.$t("packetDetail.cancel-payment-refund"))) {
        this.cancelRefundProcess(false);
      }
    },

    cancelRefundProcess(showAlert) {
      this.clearAllTimeouts();
      // Delay hiding
      delay(() => {
        this.hideOverlayWithSuccess(false, showAlert);
      }, 1000);
    },

    hideOverlayWithSuccess(success, showAlert) {
      this.$emit("hideOverlay", { success: success, showAlert: showAlert });
      this.clearErrors();
    },

    clearAllTimeouts() {
      clearTimeout(this.cancelId);
      clearInterval(this.intervalId);
    }
  }
};
</script>

<style lang="scss" scoped>
.overlay-payment {
  @include overlay;
  background: rgba(0, 0, 0, 0.95);

  .content {
    max-width: 221px;
    margin: auto;
    margin-top: 108px;

    p {
      font-size: 1rem;
      font-weight: 700;
      margin-bottom: 13px;
    }

    a {
      &:nth-of-type(1) {
        display: inline-block;
        margin-bottom: 46px;
      }

      .icon {
        vertical-align: -6px;
        margin-right: 5px;
      }
    }
  }
}
</style>
